.form-container {
  display: flex;
  flex-direction: column; /* Organiza as linhas de labels e inputs em coluna */
  border: 3px solid #000000; /* Borda externa do contêiner */
  border-radius: 12px; /* Arredonda os cantos do contêiner */
  padding: 10px; /* Espaçamento interno do contêiner */
  box-sizing: border-box; /* Inclui borda e padding na largura total do contêiner */
}

.form-group {
  display: grid;
  grid-template-columns: max-content 1fr; /* A coluna de labels se ajusta ao conteúdo máximo e a coluna de inputs ocupa o restante */
  align-items: center;
  margin-bottom: 5px; /* Espaçamento entre os grupos de labels e inputs */
}

.form-label {
  padding-right: 5px; /* Espaçamento entre a label e o input */
  white-space: nowrap; /* Evita quebra de linha no texto da label */
  text-align: right; /* Alinha o texto da label à direita */
  width: 170px; /* Garante que a largura se ajuste ao máximo conteúdo */
}

.form-group-radio {
  display: flex;
  gap: 10px;
  margin-bottom: 5px
}

.form-input {
  width: 100%; /* Faz com que o input ocupe toda a largura disponível */
  padding: 5px; /* Espaçamento interno do campo de input */
}